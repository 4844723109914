<template>
  <div>
    <el-table :data="tableData" stripe border>
      <el-table-column
        align="center"
        header-align="center"
        prop="province"
        label="全场"
      ></el-table-column>
      <el-table-column align="center" header-align="center" label="阿塞拜疆">
        <el-table-column
          align="center"
          header-align="center"
          prop="province"
          label="积分"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="province"
          label="排名"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="province"
          label="胜率"
        ></el-table-column>
      </el-table-column>
      <el-table-column align="center" header-align="center" label="阿塞拜疆">
        <el-table-column
          align="center"
          header-align="center"
          prop="province"
          label="积分"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="province"
          label="排名"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="province"
          label="胜率"
        ></el-table-column>
      </el-table-column>
    </el-table>
    <el-table :data="tableData1" stripe border>
      <el-table-column
        align="center"
        header-align="center"
        prop="province"
        label="AI统计主胜概率"
      ></el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="province"
        label="AI统计平概率"
      ></el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="province"
        label="AI统计客胜概率"
      ></el-table-column>
    </el-table>
    <el-table :data="tableData1" stripe border>
      <el-table-column
        align="center"
        header-align="center"
        prop="province"
        label="欧赔转换主胜概率"
      ></el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="province"
        label="欧赔转换主平概率"
      ></el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="province"
        label="欧赔转换客胜概率"
      ></el-table-column>
    </el-table>
    <el-table :data="tableData1" stripe border>
      <el-table-column
        align="center"
        header-align="center"
        prop="province"
        label="欧主赔概率变化"
      ></el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="province"
        label="欧平赔概率变化"
      ></el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="province"
        label="欧客赔概率变化"
      ></el-table-column>
    </el-table>

    <el-table
      :data="tableData4"
      stripe
      :show-header="false"
      style="margin-top:20px"
      border
    >
      <el-table-column prop="name"></el-table-column>
      <el-table-column prop="value"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          date: "2016-05-03",
          name: "王小虎",
          province: "海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1518 弄",
          zip: 200333
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          province: "海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1518 弄",
          zip: 200333
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          province: "海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1518 弄",
          zip: 200333
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          province: "海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1518 弄",
          zip: 200333
        }
      ],
      tableData1: [
        {
          date: "2016-05-03",
          name: "王小虎",
          province: "海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1518 弄",
          zip: 200333
        }
      ],
      tableData4: [
        {
          name: "星辰数据-两队状况评估",
          value: "两队最近表现相当"
        },
        {
          name: "星辰数据-预测盘口",
          value: "两队最近表现相当"
        },
        {
          name: "星辰数据-盘水变化分析",
          value: "两队最近表现相当"
        },
        {
          name: "星辰数据-同类盘口变化统计",
          value: "两队最近表现相当"
        },
        {
          name: "星辰数据-推荐",
          value: "两队最近表现相当"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-table thead.is-group th {
  background: red;
}
.title {
  height: 28px;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  color: #16a085;
}
.el-divider {
  margin-top: 5px;
  margin-bottom: 15px;
}
.simply {
  background: #f6f7f8;
  border-radius: 6px;
  padding: 15px;

  font-size: 14px;
  font-weight: bold;
  line-height: 26px;
  color: #333333;
}
</style>
