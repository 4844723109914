<template>
  <el-table :data="tableData" stripe border>
    <el-table-column
      prop="date"
      label="日期"
      width="180"
      align="center"
      header-align="center"
    >
    </el-table-column>
    <el-table-column
      prop="name"
      label="姓名"
      align="center"
      header-align="center"
    >
    </el-table-column>
    <el-table-column
      prop="address"
      label="地址"
      align="center"
      header-align="center"
    >
    </el-table-column>

    <el-table-column align="center" header-align="center" label="分析">
      <template slot-scope="scope">
        <el-button
          @click="handleClick(scope.row)"
          type="primary"
          size="mini"
          plain
          >分析</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄"
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄"
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄"
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄"
        }
      ]
    };
  }
};
</script>

<style></style>
